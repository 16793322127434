import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Card,
  Dropdown,
  Menu,
  Tag,
  Spin,
} from "antd";
import {
  CloseOutlined,
  LockOutlined,
  FilterOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./App.css";
import DOMPurify from "dompurify";
import { connect, useDispatch, useSelector } from "react-redux";
import { getBlogs } from "./Actions/blogs";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png";
import "../src/utils/style.scss";
import Note from "./utils/Note";
import { bindActionCreators } from "redux";
import { fetchTagList } from "./Actions/addTags";
import axios from "axios";
import { API_URL } from "./config";
import logos from "../src/images/menu-frame/new_lg.PNG";

function DemoOne({
  parasize,
  menuvalue,
  font_type,
  bgValue,
  bgparaValue,
  colorFont,
  blogs,
  lineHeight,
  setIsReflectionOrPoem,
  setShowMOdalNew,
  showModalNew,
}) {
  const [success, setsuccess] = useState([]);
  const [getdatas, setgetdatas] = useState([]);
  const currentYear = new Date().getFullYear(); //
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tagOptions, setTagOptions] = useState([]); // Store tags dynamically from API
  const [loadingTags, setLoadingTags] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTags();
  }, []);

  const filteredBlogs = getdatas.filter((poem) => {
    // Sanitizing and extracting text from the title's HTML content
    const cleanTitle = DOMPurify.sanitize(poem.title, { ALLOWED_TAGS: [] });
    const titleMatch = cleanTitle
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Ensure the tags are correctly parsed from JSON and then matched
    let tagsMatch = false;
    if (poem.tags) {
      try {
        const poemTags = JSON.parse(poem.tags); // Ensure this is an array after parsing
        tagsMatch =
          selectedTags.length === 0 || // Proceed if no tags are selected (show all)
          poemTags.some((tag) => selectedTags.includes(tag)); // Any of poem's tags is in selectedTags
      } catch (error) {
        console.error("Error parsing tags:", error);
        tagsMatch = false; // Handle parsing error
      }
    } else {
      tagsMatch = selectedTags.length === 0; // If no tags in poem and none selected, show all
    }
    return titleMatch && tagsMatch;
  });

  const fetchTags = async () => {
    setLoadingTags(true);
    try {
      const response = await axios.get(`${API_URL}/taglist`);
      if (response.data.result === "success") {
        const apiTags = response.data.data.map((tag) => tag.tag_name);
        setTagOptions(apiTags);

        // Automatically select tags that match
        const preSelectedTags = apiTags.filter((tag) =>
          selectedTags.includes(tag)
        );
        setSelectedTags(preSelectedTags); // Update selected tags
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setLoadingTags(false);
    }
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleMenuClick = (tag) => {
    // Toggle tag selection
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Dynamic tag menu
  const menu = (
    <Menu className="custom-menu">
      {loadingTags ? (
        <Menu.Item key="loading">
          <Spin size="small" />
        </Menu.Item>
      ) : (
        tagOptions.map((tag) => (
          <Menu.Item
            key={tag}
            onClick={() => handleMenuClick(tag)}
            className="menu-item"
          >
            {selectedTags.includes(tag) ? <Tag color="blue">{tag}</Tag> : tag}
          </Menu.Item>
        ))
      )}
    </Menu>
  );

  useEffect(() => {
    dispatch(getBlogs(setsuccess));
    dispatch(fetchTagList());
    setIsReflectionOrPoem(true);
  }, []);

  useEffect(() => {
    if (blogs?.length !== 0) {
      setgetdatas(blogs);
      setsuccess(true);
    }
    setIsReflectionOrPoem(true);
  }, [blogs]);

  if (success == false) {
    return (
      <div>
        <div className="contentLoader">
          <ContentBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div
        className="main_searchBox"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
        }}
      >
        {/* Logo Section */}
        <div
          className="logo-container-new"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowMOdalNew((prevShow) => !prevShow);
          }}
        >
          <img
            src={logos}
            alt="Logo"
            width={90}
            height={90}
            className="logo-image"
          />
        </div>

        {/* Search Box and Filter Section */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className="inner_searchBox"
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              gap: "10px",
            }}
          >
            {/* Search Input */}
            <Input.Search
              placeholder="Search qualities or select tags"
              onSearch={handleSearch}
              allowClear
              prefix={<SearchOutlined style={{ color: "#0F307A" }} />}
              className="searchBoxSpan"
              style={{
                flex: 1,
                maxWidth: "600px",
                // height: '40px',
                margin: "auto",
              }}
            />

            {/* Filter Dropdown */}
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="text"
                style={{
                  color: "#0F307A",
                  fontSize: "16px",
                  padding: "0 12px",
                  fontWeight: "500",
                  display: "inline-flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  height: "40px",
                }}
              >
                <FilterOutlined style={{ marginRight: "4px" }} />
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="App" style={{ overflowX: "auto", marginTop: 90 }}>
        {success == true
          ? filteredBlogs
              .filter(
                (item) =>
                  item.pageName === "blog" ||
                  item.pageName === "reflections" ||
                  item.pageName === "poems"
              )
              .map((item) => {
                const matches =
                  item.title !== null ? item.title.match(/\bql-font\w*/) : "";
                const fontClass = matches ? matches[0] : "";
                return (
                  <React.Fragment key={item.id}>
                    <div className="container-fluid post-container">
                      <div className="row">
                        <div className={`site-content ${font_type}`}>
                          <div className={`post-sec frame1 ${bgValue}`}>

                          { item?.pageName === "poems" && item.imageUrl ? (
                            <img
                              className={`image1`}
                              src={item.imageUrl}
                              alt="Image"
                              loading="lazy"
                              srcSet={item.imageUrl} // This helps in some cases to cache
                            />
                          ) : (
                            ""
                          )}
                            {(item?.pageName === "poems" &&
                              item.note !== null) ||
                            (item?.pageName === "reflections" &&
                              item.note !== null) ? (
                              <div
                                className="note-container"
                                style={{
                                  paddingTop: 0,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {item?.pageName === "poems" &&
                                item.title !== null ? (
                                  <div className="title-div-poem-note-pem">
                                    <h1
                                      class={`entry-title`}
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    ></h1>
                                  </div>
                                ) : null}

                                {item?.pageName === "reflections" &&
                                item.title !== null ? (
                                  <h1
                                    class={`entry-title`}
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  ></h1>
                                ) : null}
                              </div>
                            ) : (
                              <React.Fragment>
                                {item.title.length !== 11 ? (
                                  <h1
                                  style={{
                                    paddingTop:
                                      getdatas[0]?.pageName === "fairytale" &&
                                      getdatas[0]?.title === null
                                        ? 0
                                        : 10,
                                        paddingTop: item?.pageName === "reflections"? 70:0,
                                      marginBottom: item?.pageName === "reflections"? -20 : 0,
                                  }}
                                  className={"entry-tile"}
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  ></h1>
                                ) : <div  style={{
                                    paddingTop: item?.pageName === "reflections" ? 70:0
                                }}></div>}
                              </React.Fragment>
                            )}

                            { item?.pageName !== "poems" && item.imageUrl ? (
                              <img
                                className={`image1`}
                                src={item.imageUrl}
                                alt="Image"
                                loading="lazy"
                                srcSet={item.imageUrl} // This helps in some cases to cache
                              />
                            ) : (
                              ""
                            )}
                            
                            <div
                              className="desc_wrapper"
                              style={{
                                paddingTop:
                                  getdatas[0]?.pageName === "fairytale" &&
                                  getdatas[0]?.title === null
                                    ? 0
                                    : 10,
                                    paddingTop: item?.pageName === "reflections"? 0:0,
                                  paddingBottom: item?.pageName === "reflections"? 70 : 0,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: `${parasize}px`,
                                  color: `#0F307A`,
                                  width: "100%",
                                  display: "inline-block",
                                  whiteSpace: "pre-wrap",
                                  alignSelf: "center",
                                  
                                }}
                                className={`entry-content ${bgparaValue} ${
                                  item.space_number ? `entry-content_poem` : ""
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: item.description.replace(
                                    /&nbsp;/g,
                                    " "
                                  ),
                                }}
                              ></div>
                              {(item?.pageName === "poems" &&
                                item.note !== null) ||
                              (item?.pageName === "reflections" &&
                                item.note !== null) ? (
                                <>
                                  {item?.pageName === "poems" ||
                                  item?.pageName === "reflections" ? (
                                    <div className="sticky-box">
                                      <Note
                                        note={item.note}
                                        bg_color={item.background_colour}
                                        isPoem={10}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </div>
                          </div>
                          {item?.pageName === "poems" ||
                          item?.pageName === "reflections" ||
                          item?.pageName === "blog" ? (
                            <div
                              className={`poem-reflection-footer`}
                              style={{
                                fontSize: 12,
                                fontFamily: `${bgparaValue}`,
                              }}
                            >
                              <h>
                                © {currentYear} Ritu Dhillon All Rights
                                Reserved.
                                {"     "}
                              </h>
                              <div>
                                {""}
                                <h> {item.place ? `${item.place},` : ""} </h>
                                <h className={`${fontClass}`}>
                                  {item.year ? item.year : ""}
                                </h>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/*<div style={{width: '100%', display: 'flex',marginRight:0}}> 
        <img src={logos_back} alt="Icon" width={300} height={110}   style={{
          objectFit: 'contain',
          marginLeft:'auto', 
          margin: "auto",
        }}/></div>*/}
                    <div class="separator"></div>
                  </React.Fragment>
                );
              })
          : ""}
        {filteredBlogs.length === 0 ? (
          <div
            className=""
            style={{
              alignSelf: "center",
              marginTop: 100,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={images_nodata}
              alt=""
              width={100}
              height={100}
              style={{ margin: "auto" }}
            />
            <label
              style={{
                alignSelf: "center",
                marginTop: 30,
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              No blog found!
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  blogs: state.enroll.blogs,
  tagsList: state.enroll.tagsList,
});
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        fetchTagList,
      },
      dispatch
    ),
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DemoOne);
